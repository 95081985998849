
/*loader*/

#loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

/*loader end*/

/*login*/

.login-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.login-wrapper {
    padding: 100px 20px;
    height: 100vh;
    background: #fff;
    -webkit-box-shadow: -3px 1px 9px -3px rgba(0,0,0,0.75);
    -moz-box-shadow: -3px 1px 9px -3px rgba(0,0,0,0.75);
    box-shadow: -3px 1px 9px -3px rgba(0,0,0,0.75);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    overflow: auto;
}

.login-logo {
    display: block;
}

.login-form {
    max-width: 100%;
}

.login-form-forgot {
    float: right;
}

.login-form-button {
    width: 100%;
}


/*login end*/

/*admin*/

.admin-logo {
    width: 100%;
    height: 31px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px 24px 16px 0;
    float: left;
}

.admin-header {
    background: #fff!important;
    position: fixed!important;
    z-index: 9;
    display: flex;
    justify-content: space-around;
    right: 0!important;
    left: 250px;
    box-shadow: 0 2px 4px 0 rgba(43, 43, 43, 0.1);
}

.admin-header div {
    display: flex;
    align-items: center;
    width: 50%;
}

.admin-header div:last-child {
    justify-content: flex-end;
}

.submenu-title-wrapper {
    width: 100%;
}

.admin-sidebar-menu .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
    font-size: 12px!important;
}

.admin-sidebar-menu .ant-menu-sub .ant-menu-item {
    padding-left: 20px!important;
}

/*admin end*/

/*helpers*/

.ant-form-item {
    margin-bottom: 0!important;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
    box-shadow: 0 0 5px 0 rgba(43, 43, 43, 0.1), 0 11px 6px -7px rgba(43, 43, 43, 0.1);
    margin-bottom: 30px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 10px;
    position: relative;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card .card-header h1,
.card .card-header h2,
.card .card-header h3,
.card .card-header h4,
.card .card-header h5,
.card .card-header h6 {
    margin-bottom: 0!important;
}

.card .card-block {
    padding: 1.25rem;
}

.card-body {
    padding: 10px;
}

@media (max-width: 992px) {
    .admin-header {
        left: 0;
    }

    .d-none {
        display: none!important;
    }
}

@media (min-width: 992px) {
    .admin-header {
        left: 250px;
    }

    .active-sidebar {
        margin-left: 250px;
    }

    .desktop-hide {
        display: none!important;
    }

}

.form-inner-container {
    margin-bottom: 40px;
}

.avatar-uploader {
    width: 100%;
    display: flex!important;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ccc;
}

.page-actions {
    display: flow-root;
    text-align: right;
}

.page-actions .ant-btn {
    margin-right: 10px;
}

.image-uploader {
    max-height: 120px;
    display: block!important;
}

.col-inner {
    padding: 0 10px;
}

.ant-form h4 {
    text-align: center;
}

/*helpers end*/
